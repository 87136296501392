import HotspotBase from "./Base";
import hotspotImg from "../../../../../assets/images/cms/hotspot.png";

// type Props = {
//     index: number;
//     data: any;
//     onHotspotUpdate?: (index: number, yaw: number, pitch: number, type) => void; //TODO: if isView is false then onHotspotUpdate is required
//     isView?: boolean;
// }

const LinkHotspot = ({
  index,
  isView,
  className,
  onHotspotUpdate,
  onClick,
  data,
  sceneRef,
}) => {
  const hotspotElement = HotspotBase({
    className,
    type: "link",
    index,
    onHotspotUpdate,
    bgImage: hotspotImg,
    isView,
    sceneRef,
  });

  onClick &&
    hotspotElement.addEventListener("click", () => {
      onClick(data);
    });

  return hotspotElement;
};

export default LinkHotspot;
