/** @format */

import { isEmpty } from "lodash";
import { toast } from "react-toastify";
import React, { useState } from "react";
import { Modal } from "../../../../components/commons";
import mediaAPI from "../../../../../../apis/api/media";
import { niceBytes } from "../../../../../../helper/utils";
import playListApi from "../../../../../../apis/api/playlist";
import uploadIcon from "../../../../../../assets/images/upload.svg";
import closeIcon from "../../../../../../assets/images/close-white.svg";

export const AddEnvMapModal = ({
  show,
  setShow,
  onCreateSuccess,
}) => {
  const [files, setFiles] = useState([]);
  const [errors, setErrors] = useState({});
  const [mediaName, setMediaName] = useState('');

  const [isDragging, setIsDragging] = useState(false);
  const [isShowLoading, setShowLoading] = useState(false);

  const handleFileSelect = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setFiles(selectedFiles);
    setMediaName(selectedFiles[0]?.name);
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const droppedFiles = Array.from(e.dataTransfer.files);
    if (!droppedFiles[0].type.startsWith("image")) {
      return setErrors({ ...errors, file: "Invalid file type" });
    } else {
      setErrors({ ...errors, file: "" });
      setMediaName(droppedFiles[0]?.name);
      return setFiles(droppedFiles);
    }
  };

  const onSave = async () => {
    if (validateForm()) {
      return;
    }

    setShowLoading(true);
    try {
      const formData = new FormData();

      formData.append("file", files[0]);

      const uploaded = await mediaAPI.uploadMedia(formData);
      if (uploaded?.message) {
        throw new Error(uploaded.message);
      }

      if (uploaded?.data) {
        let params = {
          isActive: true,
          path: uploaded?.data?.path,
          thumbnailPath: uploaded?.data?.thumbnailPath,
          type: 'env_map',
          name: mediaName,
          fileName: files[0]?.name,
        };
        const res = await playListApi.createMediaPlaylist(params);
        if (res && res?.data) {
          toast.success("New EnvMap image added!");
          handleClose();
          onCreateSuccess && onCreateSuccess(res?.data);
        }
      }
    } catch (err) {
      if (err.inner) {
        const errs = {};
        err.inner.forEach((err) => {
          errs[err.path] = err.message;
        });
        setErrors(errs);
      }
      else {
        toast.error(err.message);
      }
    } finally {
      setShowLoading(false);
    }
  };

  const validateForm = () => {
    let hasErrors = Object.values(errors).find((error) => error);
    if (!mediaName) {
      setErrors({ ...errors, mediaName: "Media name is required" });
      hasErrors = true;
    }

    return !!hasErrors;
  };

  const onChangeMediaName = (value) => {
    if (!value) {
      setErrors({ ...errors, mediaName: "Media name is required" });
    } else {
      setErrors({ ...errors, mediaName: undefined });
    }

    setMediaName(value);
  };

  return (
    <Modal
      iconCancelMark={() => (
        <div className="z-[9999] cursor-pointer fixed top-[50px] right-[80px]">
          <img src={closeIcon} alt="close-icon" />
        </div>
      )}
      isLoading={isShowLoading}
      wrapperClassName="wrap-update-content-modal"
      panelClassName="w-[630px] p-0 rounded-none"
      zIndex="z-[9998]"
      maskClassName="opacity-90"
      show={show}
      setShow={handleClose}
      content={
        <div className="p-[40px] max-h-[90vh] ">
          <div className="text-white relative flex flex-col gap-10 max-h-[calc(100vh_-_290px)] p-0 overflow-y-auto">
            <div>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Image name *
                </label>
                <div className="mt-2">
                  <input
                    id="mediaName"
                    name="mediaName"
                    required
                    value={mediaName}
                    placeholder="Image name"
                    onChange={(e) => onChangeMediaName(e.target.value)}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  <span className="text-red-500 mt-1 block pl-2 text-sm">{errors.mediaName}</span>
                </div>
              </div>
              <div
                className={`relative min-h-[330px] flex flex-col justify-center mt-4 mb-4 border-dashed border-2 border-gray-400 p-4 rounded-md ${isDragging ? "bg-gray-200" : "bg-white"
                  }`}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
              >
                <input
                  type="file"
                  className="hidden"
                  id="file-upload"
                  onChange={handleFileSelect}
                  accept={".png, .webp"}
                />
                <label htmlFor="file-upload" className="cursor-pointer">
                  <div className="text-center flex flex-col items-center">
                    <p className="text-gray-600">
                      Drag & drop your files here or click to upload
                    </p>
                    {!isEmpty(files) && (
                      <img
                        src={uploadIcon}
                        alt="Upload"
                        className="w-20 h-20 relative"
                      />
                    )}
                    {files.length > 0 && (
                      <ul className="mt-4">
                        {files.map((file, index) => (
                          <li key={index} className="text-lg text-gray-800">
                            <b>{file.name}</b> - {niceBytes(file.size)}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </label>
              </div>
              <span className="text-[#ae6537] text-sm not-italic font-normal leading-[150%]">
                {errors?.file}
              </span>
            </div>
            <div
              className="font-poppins cursor-pointer text-[14px] font-[700] text-[#000] mt-[20px] px-[18px] py-[7px] uppercase border-solid border-[1px] border-[#000] w-full text-center hover:bg-black/80 hover:text-white"
              onClick={onSave}
            >
              SAVE
            </div>
          </div>
        </div>
      }
    />
  );
};
