import { cn } from "../../../../../helper/utils";

const HotspotBase = ({
  bgImage,
  className,
  type,
  index,
  onHotspotUpdate,
  sceneRef,
  isView = false,
}) => {
  const handleDragStart = (e) => {
    e.dataTransfer.setData("text/plain", "");
  };

  // Handle dragover to allow dragging
  const handleDragOver = (e) => e.preventDefault();

  // Handle drag end for position update
  const handleDragEnd = (e, index) => {
    e.preventDefault();

    const panoramaElement = document.getElementById("panorama");
    const view = sceneRef.current.view();
    const rect = panoramaElement.getBoundingClientRect();

    // Calculate coordinates based on mouse position relative to the panorama element
    const x =
      (e.clientX - rect.left) * (panoramaElement.offsetWidth / rect.width);
    const y =
      (e.clientY - rect.top) * (panoramaElement.offsetHeight / rect.height);

    // Convert the screen coordinates to Marzipano coordinates
    const coords = view.screenToCoordinates({ x, y });
    let newYaw = coords.yaw;
    let newPitch = coords.pitch;

    // Ensure the coordinates are within valid bounds
    newYaw = Math.max(-Math.PI, Math.min(Math.PI, newYaw));
    newPitch = Math.max(-Math.PI / 2, Math.min(Math.PI / 2, newPitch));

    // Update the hotspot's position
    onHotspotUpdate(index, newYaw, newPitch, type);
  };

  const hotspotElement = document.createElement("div");

  hotspotElement.className = cn("w-16 h-16 cursor-pointer relative", className);
  hotspotElement.style.backgroundImage = `url(${bgImage})`;
  hotspotElement.style.backgroundSize = "cover";
  hotspotElement.id = `hotspot-${type}-${index}`;

  if (!isView) {
    hotspotElement.setAttribute("draggable", true);
    hotspotElement.addEventListener("dragstart", handleDragStart);
    hotspotElement.addEventListener("dragover", handleDragOver);
    hotspotElement.addEventListener("dragend", (e) => handleDragEnd(e, index));
  }

  return hotspotElement;
};

export default HotspotBase;
