import React from "react";
import { cn } from "../../../../../helper/utils";
import { Button } from "../../../components/commons";
import TrashIcon from "../../../configuration/components/svgs/TrashIcon";

const PanoramaList = ({
  virtualTourData,
  setSceneIndex,
  sceneIndex,
  setVirtualTourData,
}) => {
  const handleRemoveScene = (sceneIndex) => {
    const newScenes = [...virtualTourData.scenes];
    newScenes.splice(sceneIndex, 1);

    localStorage.setItem(
      "virtual-tour",
      JSON.stringify({ ...virtualTourData, scenes: newScenes })
    );

    setSceneIndex(0);
    setVirtualTourData({
      ...virtualTourData,
      scenes: newScenes,
    });
  };

  return (
    <div className="mt-4">
      {!!virtualTourData?.scenes?.length && (
        <h3 className="font-medium mb-2">List Panorama</h3>
      )}
      {virtualTourData?.scenes?.map((scene, index) => (
        <div className="flex item-center gap-5" key={scene?.id}>
          <div
            onClick={() => setSceneIndex(index)}
            className={cn(
              "p-2 flex-1 rounded cursor-pointer hover:bg-gray-200 mb-2 transition-colors",
              {
                "bg-blue-100": index === sceneIndex,
              }
            )}
          >
            {scene.name}
          </div>
          <Button
            variant="icon"
            className="text-red-500 p-0"
            onClick={() => handleRemoveScene(index)}
          >
            <TrashIcon />
          </Button>
        </div>
      ))}
    </div>
  );
};

export default PanoramaList;
