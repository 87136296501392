/** @format */

import React, { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import jwtAuth from "../../apis/utils/jwtAuth";
import icHome from "../../assets/images/logo-bay-harbor-white.svg";
import { cn } from "../../helper/utils";
import { getS3BEMediaUrl } from "../../helper/media";
import { useSelector } from "react-redux";
import { LazyLoadImage } from "react-lazy-load-image-component";

const TopNavCMS = (props) => {
  const location = useLocation();
  const staticPage = props?.staticPage?.modules;
  const visiblePage = props?.staticPage?.props?.visible;

  const [showStaticPagesMenu, setShowStaticPagesMenu] = useState(false);

  const navigate = useNavigate();
  const getActiveClass = (path) => {
    return path === location?.pathname ? "opacity-100 underline" : "";
  };

  const logout = () => {
    jwtAuth.removeToken();
    navigate("/holding");
  };

  const activeConfig = useSelector((state) => state.cms.activeConfig);
  const logo = activeConfig?.theme?.cssVariables?.find(variable => variable.name === 'logo-cms')?.value;

  return (
    <div className="sticky top-0 bg-[#000] text-[#fff] min-h-[80px] p-[13px_57px_12px_58px]">
      <div className="flex justify-between items-center">
        <a
          className="w-[65px] h-[61px] perspective-[1000px] transition-[top] duration-200 relative cursor-grab active:transition-none"
          href="/"
        >
          <LazyLoadImage src={getS3BEMediaUrl(logo) || icHome} alt="icon-cube"/>
        </a>
        <ul className="flex gap-[35px] flex-wrap">
          <li className={cn("list-none", getActiveClass("/cms/units"))}>
            <Link to="/cms/units" className="text-decoration-none" onClick={() => { setShowStaticPagesMenu(false) }}>
              <div className="text-white font-poppins text-sm font-bold uppercase">
                UNITS
              </div>
            </Link>
          </li>
          <li className={cn("list-none", getActiveClass("/cms/amenities"))}>
            <Link to="/cms/amenities" className="text-decoration-none" onClick={() => { setShowStaticPagesMenu(false) }}>
              <div className="text-white font-poppins text-sm font-bold uppercase">
                AMENITIES
              </div>
            </Link>
          </li>
          <li className={cn("list-none", getActiveClass("/cms/transport-options"))}>
            <Link to="/cms/transport-options" className="text-decoration-none" onClick={() => { setShowStaticPagesMenu(false) }}>
              <div className="text-white font-poppins text-sm font-bold uppercase">
                TRANSPORTS
              </div>
            </Link>
          </li>
          <li className={cn("list-none", getActiveClass("/cms/customers"))}>
            <Link to="/cms/customers" className="text-decoration-none" onClick={() => { setShowStaticPagesMenu(false) }}>
              <div className="text-white font-poppins text-sm font-bold uppercase">
                CUSTOMERS
              </div>
            </Link>
          </li>
          <li className={cn("list-none", getActiveClass("/cms/content"))}>
            <Link to="/cms/content" className="text-decoration-none" onClick={() => { setShowStaticPagesMenu(false) }}>
              <div className="text-white font-poppins text-sm font-bold uppercase">
                GALLERY
              </div>
            </Link>
          </li>
          <li className={cn("list-none", getActiveClass("/cms/playlists"))}>
            <Link to="/cms/playlists" className="text-decoration-none" onClick={() => { setShowStaticPagesMenu(false) }}>
              <div className="text-white font-poppins text-sm font-bold uppercase">
                PLAYLISTS
              </div>
            </Link>
          </li>
          <li className={cn("list-none", getActiveClass("/cms/assets"))}>
            <Link to="/cms/assets" className="text-decoration-none" onClick={() => { setShowStaticPagesMenu(false) }}>
              <div className="text-white font-poppins text-sm font-bold uppercase">
                ASSETS
              </div>
            </Link>
          </li>
          <li className={cn("list-none", getActiveClass("/cms/configuration"))}>
            <Link to="/cms/configuration" className="text-decoration-none" onClick={() => { setShowStaticPagesMenu(false) }}>
              <div className="text-white font-poppins text-sm font-bold uppercase">
                CONFIGURATION
              </div>
            </Link>
          </li>
          <li className={cn("list-none", showStaticPagesMenu ? 'opacity-100 underline' : '' )}>
            <div className="text-decoration-none cursor-pointer" onClick={() => { setShowStaticPagesMenu(true) }}>
              <div className="text-white font-poppins text-sm font-bold uppercase">
                STATIC PAGES
              </div>
            </div>
          </li>
          <li className={cn("list-none", getActiveClass("/cms/faqs"))}>
            <Link to="/cms/faqs" className="text-decoration-none" onClick={() => { setShowStaticPagesMenu(false) }}>
              <div className="text-white font-poppins text-sm font-bold uppercase">
                FAQS
              </div>
            </Link>
          </li>
          <li onClick={() => logout()}>
            <div className="text-white font-poppins text-sm font-bold uppercase">
              LOG OUT
            </div>
          </li>
        </ul>
      </div>
      {
        showStaticPagesMenu && 
        <div className="flex justify-end items-center">
          <ul className="flex gap-[35px] flex-wrap w-fit pt-5 border-t border-t-white">
            {!!staticPage?.length &&
              visiblePage &&
              staticPage?.map(
                (module) =>
                  module?.props?.visible && (
                    <li
                      key={module?.id}
                      className={cn(
                        "list-none",
                        getActiveClass(
                          `/cms/${module?.props?.path}/${module?.id}`
                        )
                      )}
                    >
                      <Link
                        to={`/cms/${module?.props?.path}/${module?.id}`}
                        className="text-decoration-none"
                      >
                        <div className="text-white font-poppins text-sm font-bold uppercase">
                          {module?.name}
                        </div>
                      </Link>
                    </li>
                  )
              )}
          </ul>
        </div>
      }

    </div>
  );
};
export default TopNavCMS;
