import HotspotBase from "./Base";
import infoImg from "../../../../../assets/images/cms/info.png";

// type Props = {
//     index: number;
//     data: any;
//     onHotspotUpdate?: (index: number, yaw: number, pitch: number, type) => void; //TODO: if isView is false then onHotspotUpdate is required
//     isView?: boolean;
//     sceneRef?: Ref;
// }

const InfoHotspot = ({
  className,
  index,
  onHotspotUpdate,
  data,
  isView,
  sceneRef,
}) => {
  const hotspotElement = HotspotBase({
    className: `${className} w-10 h-10`,
    type: "info",
    index,
    onHotspotUpdate,
    bgImage: infoImg,
    isView,
    sceneRef,
  });

  // create popup element when hotspot is hovered
  const popupElement = document.createElement("div");
  popupElement.className =
    "popup absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 bg-white p-2 rounded shadow-md";
  popupElement.style.display = "none";
  popupElement.style.width = "150px";

  popupElement.innerHTML = `
    <div>
     <div class="text-xs bg-black text-white p-2">${data?.title}</div>
     <div class="text-xs bg-white text-black p-2">${data?.description}</div>
    </div>
  `;

  hotspotElement.addEventListener("mouseenter", () => {
    popupElement.style.display = "block";
  });

  hotspotElement.addEventListener("mouseleave", () => {
    popupElement.style.display = "none";
  });

  hotspotElement.appendChild(popupElement);

  return hotspotElement;
};

export default InfoHotspot;
