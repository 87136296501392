import {
  setIsLoading,
  actSetMeshList,
  receiveGLTFModels,
  receiveGLTFHelpers,
  receiveEnvMap,
} from "../../reduxs/scene/action";
import * as THREE from "three";
import React, { useEffect, useState } from "react";
import { getS3BEMediaUrl } from "../../helper/media";
import { reqGetActiveConfig } from "../../reduxs/cms/action";
import { useDispatch, useSelector } from "react-redux";
import configApi from "../../apis/api/page-configuration";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import { openDatabase, getFile, saveFile, deleteFile } from './GLTF3DCache';

const GLTF3DLoader = () => {
  const dispatch = useDispatch();

  const gltfModels = useSelector((state) => state.scene.gltfModels);
  const gltfHelpers = useSelector((state) => state.scene.gltfHelpers);
  const activeConfig = useSelector((state) => state.cms.activeConfig);

  useEffect(() => {
    loadConfig();
  }, []);

  const loadConfig = async () => {
    dispatch(reqGetActiveConfig());
  };

  const [isReady, setIsReady] = useState(false);

  const getListAssets = async (activeConfig = {}) => {
    const meshes = await configApi.getMeshesList();
    const {
      modelMedia = null,
      helperMedia = null,
      envMapMedia = null,
    } = activeConfig?.canvasProfile || {};

    const initScene = (gltf) => {
      const models = gltf?.scene?.children?.map(c => {
        const objName = String(c.children[0]?.name).toLowerCase();

        if (meshes && meshes.data) {
          const mesh = meshes.data.find(a => String(a.name).toLowerCase() === objName);
          if (mesh) {
            const userData = {
              alpha: mesh.alpha != null ? mesh.alpha / 100.0 : 1.0,
              hover_alpha: mesh.hover_alpha != null ? mesh.hover_alpha / 100.0 : 1,
              active_alpha: mesh.active_alpha != null ? mesh.active_alpha / 100.0 : 1.0,
              color: mesh.color ?? "#999999",
              hover_color: mesh.hover_color ?? mesh.color,
              active_color: mesh.active_color ?? mesh.color,
              isActive: !!c.children[0].userData?.isActive,
              layer: mesh.layer,
              emissive: mesh.emissive ?? "#000000",
              active_emissive: mesh.active_emissive ?? "#554e2d",
              camVectors: mesh.camVectors || null,
            };

            if (c.children[0].material) {
              if (mesh.roughness || mesh.roughness === 0) c.children[0].material.roughness = mesh.roughness; 
              if (mesh.metalness || mesh.roughness === 0) c.children[0].material.metalness = mesh.metalness; 
              if (mesh.opacity || mesh.roughness === 0) c.children[0].material.opacity = mesh.opacity; 
              if (mesh.color) c.children[0].material.color = new THREE.Color(mesh.color);
              if (mesh.emissive) c.children[0].material.emissive = new THREE.Color(mesh.emissive);
            }

            c.children[0].userData = userData;
          }
        }

        return {
          ...c,
          name: objName || "",
        }
      }) || [];
      dispatch(actSetMeshList(meshes));
      dispatch(receiveGLTFModels(models));
      dispatch(setIsLoading(false));
    };

    const prepareSceneHelpers = (camHelpers) => {
      const helpers = camHelpers?.scene?.children?.map(c => {
        return {
          uuid: c.uuid,
          name: c.name,
          position: c.position,
        }
      }) || [];
      dispatch(receiveGLTFHelpers(helpers));
    };

    const loadEnvMap = (envMapPath = '') => {
      if (!envMapPath) {
        return;
      }
      const textureLoader = new THREE.TextureLoader();
      textureLoader.setCrossOrigin("anonymous");
      textureLoader.setRequestHeader({ 'access-control-allow-origin': '*' });
      textureLoader.setPath(getS3BEMediaUrl(envMapPath) + '?not-from-cache-please')
      const texture = textureLoader.load();
      texture.mapping = THREE.EquirectangularReflectionMapping;
      dispatch(receiveEnvMap(texture));
    }

    const customGLTFLoader = async (path, onComplete) => {
      let loader = new GLTFLoader();
      const dracoLoader = new DRACOLoader();
      dracoLoader.setDecoderPath("/draco/");
      loader.setDRACOLoader(dracoLoader);
    
      let response = null;
      const db = await openDatabase();
      const cachedFile = await getFile(db, path);
    
      if (cachedFile) {
        // Load model from cache
        const blobUrl = URL.createObjectURL(cachedFile.file);
        loader.load(blobUrl, (gltf) => {
          onComplete(gltf);    
          URL.revokeObjectURL(blobUrl);
        });
      } else {
        try {
          // Load models from network
          const response = await fetch(getS3BEMediaUrl(path));
          const arrayBuffer = await response.arrayBuffer();
          const blob = new Blob([new Uint8Array(arrayBuffer)], { type: 'model/gltf-binary' });
          const blobUrl = URL.createObjectURL(blob);
    
          loader.load(blobUrl, async (gltf) => {
            onComplete(gltf);    
            URL.revokeObjectURL(blobUrl);
          })
    
          // Delete old cache, and save new file
          if (cachedFile) {
            await deleteFile(db, path);
          }
    
          await saveFile(db, path, blob, 1);
        } catch (error) {
          console.error("Error fetching the model:", error);
        }
      }
      return response;
    }

    if (
      !modelMedia || !modelMedia?.path ||
      !helperMedia || !helperMedia?.path ||
      !envMapMedia || !envMapMedia?.path
    ) {
      initScene();
      prepareSceneHelpers();
      loadEnvMap();
      dispatch(setIsLoading(false));
      return;
    }

    // load main model and init scene
    await customGLTFLoader(
      modelMedia?.path,
      initScene,
    );
    // load helper model
    await customGLTFLoader(
      helperMedia?.path,
      prepareSceneHelpers,
    );
    // load envMap
    await loadEnvMap(envMapMedia?.path);
  }

  useEffect(() => {
    getListAssets(activeConfig);
  }, [activeConfig]);

  useEffect(() => {
    if (gltfModels?.length && gltfHelpers?.length && !isReady) {
      const newList = gltfModels.map(m => {
        const camVectors = m?.children?.[0]?.userData?.camVectors || null;
        if (camVectors) {
          const helperObj = Object.fromEntries(Object.entries(camVectors).map(([key, value]) => {
            const helper = gltfHelpers.find(h => h.name === value);
            return [key, helper?.position || null];
          }));
          if (helperObj) m.camHelpers = helperObj;
        }
        return m;
      });
      if (newList.length) setIsReady(true);
    }
  }, [gltfModels, gltfHelpers, isReady]);

  return <></>;
};

export default GLTF3DLoader;
