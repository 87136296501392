import React, { useState, useEffect } from "react";
import * as yup from "yup";
import { toast } from "react-toastify";
import closeIcon from "../../../assets/images/close-white.svg";
import uploadIcon from "../../../assets/images/upload.svg";
import transportAPI from "../../../apis/api/transportOption";
import { Button, Modal } from "../components/commons";
import mediaAPI from "../../../apis/api/media";
import playListAPI from "../../../apis/api/playlist";
import { isEmpty } from "lodash";
import { niceBytes } from "../../../helper/utils";
import { getS3BEMediaUrl } from "../../../helper/media";

const validationSchema = yup.object().shape({
  name: yup.string().trim().required("Name is a required field"),
  modalDescription: yup.string().trim().required("Description is a required field"),
});

export const EditModal = ({ data, show, setShow, onSaveSuccess }) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [errors, setErrors] = useState({});
  const [files, setFiles] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [isShowLoading, setShowLoading] = useState(false);

  useEffect(() => {
    if (data) {
      setName(data.name || '');
      setDescription(data.modal?.description || '');
    }
  }, [data]);

  const handleFileSelect = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setFiles(selectedFiles);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const droppedFiles = Array.from(e.dataTransfer.files);
    if (!droppedFiles[0].type.startsWith("image")) {
      return setErrors({ ...errors, file: "Invalid file type" });
    } else {
      setErrors({ ...errors, file: "" });
      return setFiles(droppedFiles);
    }
  };

  const validateForm = () => {
    return !!Object.values(errors).find((error) => error);
  };

  const handleUpdate = async () => {
    if (!data) return;

    setShowLoading(true);

    try {
      const formData = new FormData();
      let imgId = data?.modal?.image?.id;

      if (files?.[0]) {
        formData.append("file", files[0]);

        const uploaded = await mediaAPI.uploadMedia(formData);
        if (uploaded?.message) {
          throw new Error(uploaded.message);
        }

        if (uploaded?.data) {
          let params = {
            isActive: true,
            path: uploaded?.data?.path,
            thumbnailPath: uploaded?.data?.thumbnailPath,
            type: 'image',
            name: files[0]?.name,
            fileName: files[0]?.name,
          };
          const res = await playListAPI.createMediaPlaylist(params);
          if (res && res?.data) {
            imgId = res?.data?.id;
          }
        }
      }

      const payload = {
        name,
        modalDescription: description,
        modalImage: imgId,
      };
      setErrors({});
      const result = await validationSchema.validate(payload, {
        abortEarly: false,
      });

      const res = await transportAPI.editTransportOptions(data.id, result);
      if (res.data) {
        toast.success("Transport option updated successfully!");
        handleClose && handleClose();
        onSaveSuccess && onSaveSuccess();
      }
      setShowLoading(false);
    } catch (err) {
      if (err.inner) {
        const errs = {};
        err.inner.forEach((err) => {
          errs[err.path] = err.message;
        });
        setErrors(errs);
      } else {
        toast.error(err.message)
      }
      setShowLoading(false);
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  return (
    <Modal
      iconCancelMark={() => (
        <div className="z-[9999] cursor-pointer fixed top-[50px] right-[80px]">
          <img src={closeIcon} alt="close-icon" />
        </div>
      )}
      wrapperClassName="wrap-update-data-modal"
      panelClassName="w-[698px] p-0 rounded-none"
      zIndex="z-[9998]"
      maskClassName="opacity-90"
      show={show}
      setShow={handleClose}
      content={
        <div className="p-[40px] modal-content">
          <h2 className="text-black text-[30px] font-[700] uppercase font-poppins mb-5">
            Update Transport Option
          </h2>
          <div className="wrap-modal-body">
            <div className="flex flex-wrap mb-[15px]">
              <label className="w-full text-[14px] font-bold mb-[15px] uppercase text-[#000] font-poppins">
                Name*
              </label>
              <input
                id="name"
                name="name"
                value={name}
                className="w-full focus-visible:outline-none !focus-visible:shadow-none h-10 border-b-[1px] border-solid border-[#000] font-poppins text-[14px] font-[400] text-[#000] "
                placeholder="Name"
                onChange={(e) => setName(e.target.value)}
              />
              <span className="text-red-500 mt-2">{errors?.name}</span>
            </div>
            <div className="flex flex-wrap mb-[15px]">
              <label className="w-full text-[14px] font-bold mb-[15px] uppercase text-[#000] font-poppins">
                Description*
              </label>
              <textarea
                id="description"
                name="modal.description"
                value={description}
                className="w-full focus-visible:outline-none !focus-visible:shadow-none min-h-[200px] border-b-[1px] border-solid border-[#000] font-poppins text-[14px] font-[400] text-[#000] "
                placeholder="Description"
                onChange={(e) => setDescription(e.target.value)}
              />
              <span className="text-red-500 mt-2">{errors?.description}</span>
            </div>
            <div className="flex flex-wrap w-full">
              <label className="w-full text-[14px] font-bold mb-[15px] uppercase text-[#000] font-poppins">
                Media*
              </label>
              <div
                className={`w-full relative min-h-[200px] flex flex-col justify-center mb-4 border-dashed border-2 border-gray-400 p-4 rounded-md ${isDragging ? "bg-gray-200" : "bg-white"
                  }`}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
              >
                <input
                  type="file"
                  className="hidden"
                  id="file-upload"
                  onChange={handleFileSelect}
                  accept={".png, .webp"}
                  multiple={false}
                />
                <label htmlFor="file-upload" className="cursor-pointer">
                  <div className="text-center flex flex-col items-center">
                    <p className="text-gray-600">
                      Drag & drop your files here or click to upload
                    </p>
                    {!isEmpty(files) && (
                      <img
                        src={uploadIcon}
                        alt="Upload"
                        className="w-18 h-18 relative"
                      />
                    )}
                    {
                      files.length > 0
                        ? (
                          <ul className="mt-4">
                            {files.map((file, index) => (
                              <li key={index} className="text-lg text-gray-800">
                                <b>{file.name}</b> - {niceBytes(file.size)}
                                <div className="w-full flex flex-col justify-center items-center mt-3">
                                  <img className="max-w-[400px]" src={URL.createObjectURL(files?.[0])} />
                                </div>
                              </li>
                            ))}
                          </ul>
                        )
                        : data?.modal?.image?.thumbnailPath
                          ? <div className="w-full flex flex-col justify-center items-center mt-3">
                            <img className="max-w-[400px]" src={getS3BEMediaUrl(data?.modal?.image?.thumbnailPath)} />
                          </div>
                          : null
                    }
                  </div>
                </label>
              </div>
              <span className="text-[#ae6537] text-sm not-italic font-normal leading-[150%]">
                {errors?.file}
              </span>
            </div>
            {
              data?.['3d_mesh'] &&
              <div className="flex flex-wrap mb-[15px]">
                <label className="w-full text-[14px] font-bold mb-[15px] uppercase text-[#000] font-poppins">
                  3D Mesh*
                </label>
                <input
                  id="_3dMesh"
                  name="_3dMesh"
                  disabled
                  value={data?.['3d_mesh']}
                  className="w-full focus-visible:outline-none !focus-visible:shadow-none h-10 border-b-[1px] border-solid border-[#000] font-poppins text-[14px] font-[400] text-[#000] disabled:bg-gray-200 disabled:opacity-70"
                />
              </div>
            }
          </div>
          <Button
            className="flex bg-white text-black !rounded-none justify-center items-center flex-1 h-12 px-[18px] py-[12px] m-0 gap-[10px] border border-black font-poppins text-base font-bold w-full leading-[10px] hover:text-white hover:bg-black hover:cursor-pointer !opacity-100"
            onClick={handleUpdate}
            isLoading={isShowLoading}
          >
            Save
          </Button>
        </div>
      }
    />
  );
};
