import React, { useEffect, useState, useRef, useCallback } from "react";
import { useSelector } from "react-redux";
import * as PANOLENS from "panolens";
import { ImageLoader } from "three";
import { getS3BEMediaUrl } from "../../helper/media";
import classNames from "classnames";
import { WEBSOCKET_CHANNEL } from "../../constants/options";
import socket from "../../helper/socket";
import { Vector3 } from "three";
import { cn } from "../../helper/utils";

const VirtualModal = ({ isPresentation }) => {
  const modal = useSelector((state) => state.exploreModal.modal);
  const [currentImage, setCurrentImage] = useState(
    getS3BEMediaUrl(modal?.virtualTour?.playlist?.medias[0]?.path)
  );
  const isShowAmenityVirtualTour = useSelector(
    (state) => state.exploreModal.isShowAmenityVirtualTour
  );

  const loader = useRef(new ImageLoader());
  const viewerRef = useRef(null);
  const containerRef = useRef(null);
  const panoramaRef = useRef(null);
  const mountedRef = useRef(true);
  const infospots = useRef([]);

  const authUser = useSelector((state) => state.user.data);

  // Cleanup function for the entire component
  const cleanup = useCallback(() => {
    if (!mountedRef.current) return;

    // Clear all infospots
    infospots.current.forEach((infospot) => {
      if (infospot && infospot.element) {
        infospot.element.remove();
      }
    });
    infospots.current = [];

    // Remove panorama
    if (panoramaRef.current) {
      if (viewerRef.current) {
        viewerRef.current.remove(panoramaRef.current);
      }
      panoramaRef.current.removeEventListener();
      panoramaRef.current = null;
    }

    // Dispose viewer
    if (viewerRef.current) {
      // Remove all callbacks
      if (viewerRef.current.updateCallbacks) {
        viewerRef.current.updateCallbacks.length = 0;
      }

      // Stop auto-rotation and any ongoing animations
      viewerRef.current.stopAnimation();
      viewerRef.current.disableAutoRate();

      // Remove all controls
      if (viewerRef.current.control) {
        viewerRef.current.control.dispose();
      }

      // Remove renderer and its DOM element
      if (viewerRef.current.renderer) {
        viewerRef.current.renderer.dispose();
        if (viewerRef.current.renderer.domElement) {
          viewerRef.current.renderer.domElement.remove();
        }
      }

      // Finally dispose the viewer
      viewerRef.current.dispose();
      viewerRef.current = null;
    }

    // Clear container
    if (containerRef.current) {
      containerRef.current.innerHTML = "";
    }
  }, []);

  const sendCameraPos = useCallback(() => {
    if (isPresentation || !mountedRef.current || !viewerRef.current?.camera)
      return;

    const camera = viewerRef.current.camera;
    socket.emit(WEBSOCKET_CHANNEL.SHARE_PANORAMA_ACTION, {
      content: {
        position: camera.position,
        quaternion: camera.quaternion,
        rotationY: panoramaRef.current?.rotation?.y,
        fov: camera.fov,
        imageUrl: currentImage,
      },
      to: authUser?.id,
      from: authUser?.id,
    });
  }, [currentImage, authUser?.id, isPresentation]);

  function temporaryStopRotate() {
    if (!viewerRef.current || !mountedRef.current) return;

    viewerRef.current.disableAutoRate();
    const timeoutId = setTimeout(() => {
      if (mountedRef.current && viewerRef.current) {
        viewerRef.current.enableAutoRate();
      }
    }, 3000);

    // Store timeout ID for cleanup
    return () => clearTimeout(timeoutId);
  }

  // Initialize viewer
  useEffect(() => {
    mountedRef.current = true;

    if (!viewerRef.current && containerRef.current) {
      viewerRef.current = new PANOLENS.Viewer({
        container: containerRef.current,
        controlButtons: [],
        autoRotateSpeed: 0.5,
        ...(!isPresentation ? { autoRotate: true } : {}),
      });

      viewerRef.current.camera.fov = 80;
      viewerRef.current.camera.updateProjectionMatrix();

      window.addEventListener("click", temporaryStopRotate);
    }

    return () => {
      mountedRef.current = false;
      window.removeEventListener("click", temporaryStopRotate);
      cleanup();
    };
  }, [cleanup]);

  // Camera position updates
  useEffect(() => {
    if (!viewerRef.current || isPresentation) return;

    const viewer = viewerRef.current;
    viewer.addUpdateCallback(sendCameraPos);

    return () => {
      if (viewer?.updateCallbacks) {
        const index = viewer.updateCallbacks.indexOf(sendCameraPos);
        if (index > -1) {
          viewer.updateCallbacks.splice(index, 1);
        }
      }
    };
  }, [sendCameraPos, isPresentation]);

  // Socket management
  useEffect(() => {
    if (!viewerRef.current || !isPresentation) return;

    const listenerCameraAction = ({ content }) => {
      if (!mountedRef.current || !viewerRef.current?.camera) return;

      const camera = viewerRef.current.camera;
      camera.position.copy(content.position);
      camera.quaternion.copy(content.quaternion);
      camera.fov = content.fov;

      if (panoramaRef.current?.rotation) {
        panoramaRef.current.rotation.y = content.rotationY;
      }

      camera.updateProjectionMatrix();

      if (content.imageUrl !== currentImage) {
        setCurrentImage(content.imageUrl);
      }
    };

    const timeoutId = setTimeout(() => {
      if (mountedRef.current) {
        socket.on(
          WEBSOCKET_CHANNEL.SHARE_PANORAMA_ACTION,
          listenerCameraAction
        );
      }
    }, 1000);

    return () => {
      clearTimeout(timeoutId);
      socket.off(WEBSOCKET_CHANNEL.SHARE_PANORAMA_ACTION, listenerCameraAction);
    };
  }, [isPresentation, currentImage]);

  const changePanorama = useCallback(
    async (imageUrl) => {
      if (!viewerRef.current || !mountedRef.current) return;

      try {
        const image = await new Promise((resolve, reject) => {
          loader.current.load(imageUrl, resolve, undefined, reject);
        });

        if (!mountedRef.current) return;

        // Clean up old panorama
        if (panoramaRef.current) {
          viewerRef.current.remove(panoramaRef.current);
          panoramaRef.current.removeEventListener();
        }

        const newPano = new PANOLENS.ImagePanorama(image);
        panoramaRef.current = newPano;

        if (mountedRef.current) {
          viewerRef.current.add(newPano);
          viewerRef.current.setPanorama(newPano);

          if (!isPresentation) {
            addHotspots(newPano);
          }
        }
      } catch (error) {
        console.error("Error changing panorama:", error);
      }
    },
    [isPresentation]
  );

  // Image change handler
  useEffect(() => {
    if (viewerRef.current && currentImage && mountedRef.current) {
      changePanorama(currentImage);
    }
  }, [currentImage, changePanorama]);

  const handleHotspotClick = useCallback(
    (newImageUrl) => {
      if (newImageUrl !== currentImage && mountedRef.current) {
        setCurrentImage(newImageUrl);
      }
    },
    [currentImage]
  );

  const addHotspots = useCallback(
    (panorama) => {
      if (!mountedRef.current) return;

      // Clear existing infospots
      infospots.current.forEach((infospot) => {
        if (infospot && infospot.element) {
          infospot.element.remove();
        }
      });
      infospots.current = [];

      const hotspots = (modal?.virtualTour?.playlist?.medias || [])
        .filter((item) => getS3BEMediaUrl(item?.path) !== currentImage)
        .map((item, index) => {
          const randomX =
            index % 2 === 0
              ? 2000 + (index + 1) * 500
              : -2000 - (index + 1) * 500;
          const randomY =
            index % 2 === 0 ? 0 + (index + 1) * 500 : -100 - (index + 1) * 500;
          const randomZ =
            index % 2 === 0
              ? 2000 + (index + 1) * 200
              : -2000 - (index + 1) * 200;

          return {
            imageUrl: getS3BEMediaUrl(item?.path),
            text: item?.name,
            position: new Vector3(randomX, randomY, randomZ),
          };
        });

      hotspots.forEach((hotspot) => {
        if (!mountedRef.current) return;

        const infospot = new PANOLENS.Infospot(350, PANOLENS.DataImage.Arrow);
        infospot.position.copy(hotspot.position);
        infospot.addEventListener("click", () =>
          handleHotspotClick(hotspot.imageUrl)
        );
        infospot.addHoverText(hotspot.text);
        infospot.lockHoverElement();
        panorama.add(infospot);

        infospots.current.push(infospot);
      });
    },
    [modal?.virtualTour?.playlist?.medias, currentImage, handleHotspotClick]
  );

  return (
    <div
      className={cn(
        "w-screen absolute z-[1000] right-0",
        isPresentation ? "h-dvh top-0" : "h-[calc(100dvh_-_96px)] top-[48px]",
        {
          hidden: !isShowAmenityVirtualTour,
        }
      )}
    >
      <div ref={containerRef} className="w-full h-full" />
    </div>
  );
};

export default VirtualModal;
