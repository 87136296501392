import React, { useState, useEffect } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { useDispatch, useSelector } from "react-redux";
import SearchIcon from "../components/Search";
import { reqGetListAmenities } from "../../../reduxs/cms/action";
import EditAmenityModal from "./EditAmenityModal";
import CmsPageHeader from "../components/PageHeader";
import dayjs from "dayjs";
import { getS3BEMediaUrl } from "../../../helper/media";
import { Photo } from "../../../components/svgs/icons";
import classNames from "classnames";

const Row = ({ data, onEditAmenity, head = false }) => {
  if (head) {
    return <tr className="table w-full table-fixed mb-[60px] uppercase">
      <th className="p-2 align-top w-[32px]"></th>
      <th className="p-2 align-top w-[20%] text-left">
        Name
      </th>
      <th className="p-2 align-top w-[26%] text-left">
        Description
      </th>
      <th className="p-2 align-top w-[20%] min-w-[120px] text-left">
        Image
      </th>
      <th className="p-2 align-top w-[15%] text-left">
        Virtual tour
      </th>
      {/* <th className="p-2 align-top w-[15%] text-left">
        3D Mesh name
      </th> */}
      <th className="p-2 align-top w-[13%] text-left">
        Created at
      </th>
      <th className="p-2 align-top w-[40px]" />
    </tr>;
  }

  const [show, setShow] = useState(false);

  return (
    <tr
      key={data?.id}
      className={`p-3 table w-full table-fixed my-2 border-b-[1px] border-solid border-[#C5C5C566] ${!show ? "collapsed" : ""}`}
    >
      <td className="p-2 align-top w-[32px]">
        <img
          onClick={() => { setShow(!show) }}
          src={`/icons/arrow-${show ? "up" : "down"}.svg`}
          alt=""
          className="arrow mt-2"
        />
      </td>
      <td className="p-2 align-top w-[20%] td-name">
        <div className={classNames(
          "w-full overflow-hidden",
          show ? '' : 'h-5 w-[170px] text-ellipsis whitespace-pre',
        )}>{data?.modal?.name}</div>
      </td>
      <td className="p-2 align-top w-[26%] td-description">
        <div className={classNames(
          "w-full overflow-hidden",
          show ? '' : 'h-5 w-[285px] text-ellipsis whitespace-pre',
        )}>{data?.modal?.description}</div>
      </td>
      <td className="p-2 align-top w-[20%] min-w-[120px] td-description">
        <div className="flex gap-2 w-full overflow-hidden whitespace-pre-line" onClick={() => onEditAmenity(data)}>
          <Photo className="!h-5 w-4 shrink-0 cursor-pointer" />
          <div>{data?.modal?.image?.name || 'No image'}</div>
        </div>
        {(data?.modal?.image && show) && <img className="h-32 mt-3" src={getS3BEMediaUrl(data?.modal?.image?.path)} />}
      </td>
      <td className="p-2 align-top w-[15%] td-description">
        {/* TODO: virtual tour */}
        {/* data?.modal?.virtualTour */}
      </td>
      {/* <td className="p-2 align-top w-[15%] td-description">
        {data?.['3d_mesh']}
      </td> */}
      <td className="p-2 align-top w-[13%]">
        {data?.createdAt ? dayjs(data.createdAt).format('YYYY-MM-DD HH:mm') : ''}
      </td>
      <td className="p-2 align-top w-[40px] td-edit" onClick={() => onEditAmenity(data)}>
        <img className="img-fluid" src="/icons/edit-button.svg" alt="" />
      </td>
    </tr>
  );
}

const CMSAmenities = () => {
  const dispatch = useDispatch();

  const [isShowEditAmenityModal, setIsShowEditAmenityModal] = useState(false);
  const [selectedAmenity, setSelectedAmenity] = useState(null);
  const [search, setSearch] = useState('');
  const [isSortAsc, toggleSortAsc] = useState(true);

  const amenities = useSelector((state) => state.cms.amenities);

  useEffect(() => {
    getListAmenities();
  }, [search, isSortAsc])

  useEffect(() => {
    getListAmenities();
  }, []);

  const getListAmenities = () => {
    dispatch(reqGetListAmenities({
      search,
      sortBy: JSON.stringify({
        name: isSortAsc ? 1 : -1,
      }),
    }));
  }

  const onSaveSuccess = () => {
    getListAmenities();
  }

  const onEditAmenity = (amenity) => {
    setSelectedAmenity(amenity);
    setIsShowEditAmenityModal(true);
  }

  const onSearch = (e) => {
    setSearch(e.target?.value);
  }

  const renderListProperty = () => {
    return (amenities || []).map((item, index) => {
      return (
        <CSSTransition key={item.id} timeout={200} classNames="fade-item">
          <Row
            data={item}
            index={index}
            onEditAmenity={onEditAmenity}
          />
        </CSSTransition>
      );
    });
  };

  const renderTable = () => {
    return (
      <div className="table-responsive-custom w-full p-[100px] pb-0">
        <table className="table-custom text-[#1A1A1A] text-sm font-poppins">
          <thead><Row head />
          </thead>
          <div className="spacing-header" />
          <tbody
            style={{
              display: "block",
              maxHeight: "calc(100vh - 430px)",
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            <TransitionGroup>
              {renderListProperty()}
            </TransitionGroup>
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div className="page-container">
      <CmsPageHeader title={'AMENITIES CMS'} />
      <div className="page-body">
        {renderTable()}
      </div>
      {
        isShowEditAmenityModal &&
        <EditAmenityModal
          show={isShowEditAmenityModal}
          setShow={setIsShowEditAmenityModal}
          amenity={selectedAmenity}
          onSaveSuccess={onSaveSuccess}
        />
      }
      {/* <SearchIcon handleInputChange={onSearch} /> */}
    </div>
  );
};
export default CMSAmenities;
