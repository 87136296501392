import Info from "./commons/Hotspot/Info";
import Link from "./commons/Hotspot/Link";

export const renderInfoHotspots = ({ sceneRef, infoHotspots }) => {
  infoHotspots?.forEach((info, index) => {
    const { pitch, yaw, title, description } = info;

    const hotspotElement = Info({
      index,
      data: {
        description,
        title,
      },
      isView: true,
      sceneRef,
    });

    sceneRef.current
      .hotspotContainer()
      .createHotspot(hotspotElement, { yaw, pitch });
  });
};

export const renderLinkHotspots = ({
  sceneRef,
  linkHotspots,
  isView,
  onClick,
}) => {
  linkHotspots?.forEach((link, index) => {
    const { pitch, yaw, targetScene } = link;

    const hotspotElement = Link({
      index,
      data: {
        targetScene,
      },
      isView,
      sceneRef,
      onClick,
    });

    sceneRef.current
      .hotspotContainer()
      .createHotspot(hotspotElement, { yaw, pitch });
  });
};
