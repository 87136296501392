import { EditModal } from "./EditModal";
import React, { useState, useEffect } from "react";
import CmsPageHeader from "../components/PageHeader";
import { useDispatch, useSelector } from "react-redux";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { reqGetListTransportOptions } from "../../../reduxs/cms/action";
import dayjs from "dayjs";
import { getS3BEMediaUrl } from "../../../helper/media";
import { Photo } from "../../../components/svgs/icons";
import classNames from "classnames";

const Row = ({ data, onEdit, head = false }) => {
  if (head) {
    return <tr className="table w-full table-fixed mb-[60px] uppercase">
      <th className="p-2 align-top w-[32px]"></th>
      <th className="p-2 align-top w-[16%] text-left">
        Name
      </th>
      <th className="p-2 align-top w-[26%] text-left">
        Description
      </th>
      <th className="p-2 align-top w-[20%] min-w-[120px] text-left">
        Image
      </th>
      <th className="p-2 align-top w-[15%] text-left">
        Virtual tour
      </th>
      {/* <th className="w-[26%] text-left">
        3D Mesh names
      </th> */}
      <th className="w-[15%] text-left">
        Created at
      </th>
      <th className="w-[40px]" />
    </tr>;
  }

  const [show, setShow] = useState(false)

  return (
    <tr
      key={data?.id}
      className={`table w-full table-fixed my-2 border-b-[1px] border-solid border-[#C5C5C566] ${!show ? "collapsed" : ""}`}
    >
      <td className="p-2 w-[32px]">
        <img
          onClick={() => { setShow(!show) }}
          src={`/icons/arrow-${show ? "up" : "down"}.svg`}
          alt=""
          className="mt-2 arrow"
        />
      </td>
      <td className="p-2 align-top w-[16%] td-name">
        <div className={classNames(
          "w-full overflow-hidden",
          show ? '' : 'h-5 w-[170px] text-ellipsis whitespace-pre',
        )}>{data?.modal?.name}</div>
      </td>
      <td className="p-2 align-top w-[26%] td-description">
        <div className={classNames(
          "w-full overflow-hidden",
          show ? '' : 'h-5 w-[285px] text-ellipsis whitespace-pre',
        )}>{data?.modal?.description}</div>
      </td>
      <td className="p-2 align-top w-[20%] min-w-[120px] td-description">
        <div className="flex gap-2 w-full overflow-hidden" onClick={() => onEdit(data)}>
          <Photo className="!h-5 w-4 shrink-0 cursor-pointer" />
          <div className="max-w-44 overflow-hidden text-ellipsis">{}</div>
          <div className={classNames(
            "w-full overflow-hidden",
            show ? '' : 'h-5 w-44 text-ellipsis whitespace-pre',
          )}>{data?.modal?.image?.name || 'No image'}</div>
        </div>
        {(data?.modal?.image && show) && <img className="h-48 mt-3 w-48 object-contain" src={getS3BEMediaUrl(data?.modal?.image?.path)} />}
      </td>
      <td className="p-2 align-top w-[15%] td-description">
        {/* TODO: virtual tour */}
        {/* data?.modal?.virtualTour */}
      </td>
      {/* <td className="w-[26%]">
        {data?.['3d_meshes']?.join(', ')}
      </td> */}
      <td className="w-[15%]">
        {data?.createdAt ? dayjs(data.createdAt).format('YYYY-MM-DD HH:mm') : ''}
      </td>
      <td className="w-[40px] td-edit" onClick={() => onEdit(data)}>
        <img className="img-fluid" src="/icons/edit-button.svg" alt="" />
      </td>
    </tr>
  );
}

const CMSTransportOptions = () => {
  const dispatch = useDispatch();

  const [isShowEditModal, setIsShowEditModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [search, setSearch] = useState('');
  const [isSortAsc, toggleSortAsc] = useState(true);

  const transportOptions = useSelector((state) => state.cms.transportOptions);

  useEffect(() => {
    fetchDataList();
  }, [search, isSortAsc])

  useEffect(() => {
    fetchDataList();
  }, []);

  const fetchDataList = () => {
    dispatch(reqGetListTransportOptions({
      search,
      sortBy: JSON.stringify({
        name: isSortAsc ? 1 : -1,
      }),
    }));
  }

  const onSaveSuccess = () => {
    fetchDataList();
  }

  const onEdit = (item) => {
    setSelectedItem(item);
    setIsShowEditModal(true);
  }

  const onSearch = (e) => {
    setSearch(e.target?.value);
  }

  const renderListProperty = () => {
    return (transportOptions || []).map((item, index) => {
      return (
        <CSSTransition key={item.id} timeout={200} classNames="fade-item">
          <Row
            data={item}
            index={index}
            onEdit={onEdit}
          />
        </CSSTransition>
      );
    });
  };

  const renderTable = () => {
    return (
      <div className="table-responsive-custom w-full pt-[100px] px-20 pb-0">
        <table className="table-custom text-[#1A1A1A] text-sm font-poppins">
          <thead><Row head /></thead>
          <div className="spacing-header" />
          <tbody
            style={{
              display: "block",
              maxHeight: "calc(100vh - 430px)",
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            <TransitionGroup>
              {renderListProperty()}
            </TransitionGroup>
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div className="page-container">
      <CmsPageHeader title={'TRANSPORT OPTIONS CMS'} />
      <div className="page-body">
        {renderTable()}
      </div>
      {
        isShowEditModal &&
        <EditModal
          show={isShowEditModal}
          setShow={setIsShowEditModal}
          data={selectedItem}
          onSaveSuccess={onSaveSuccess}
        />
      }
      {/* <SearchIcon handleInputChange={onSearch} /> */}
    </div>
  );
};
export default CMSTransportOptions;
