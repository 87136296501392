import React, { useState } from "react";

import { Button } from "../../components/commons";
import UploadImage from "./Upload";
import { getS3BEMediaUrl } from "../../../../helper/media";
import View from "./View";
import PanoramaList from "./PanoramaList";
import HotspotList from "./HospotList";

const Setting = ({
  setIsSetting,
  setVirtualTourData,
  virtualTourData,
  isSetting,
}) => {
  const [sceneIndex, setSceneIndex] = useState(0);

  const handleImageUpload = (path) => {
    const newScene = {
      id: `scene-${virtualTourData.scenes.length}`,
      name: `Scene ${virtualTourData.scenes.length + 1}`,
      url: `${getS3BEMediaUrl(path)}`,
      levels: [
        { tileSize: 256, size: 256, fallbackOnly: true },
        { tileSize: 512, size: 512 },
        { tileSize: 512, size: 1024 },
        { tileSize: 512, size: 2048 },
        { tileSize: 512, size: 4096 },
      ],
      faceSize: 4096,
      initialViewParameters: {
        pitch: 0,
        yaw: 0,
        fov: Math.PI / 3,
      },
      linkHotspots: [],
      infoHotspots: [],
    };

    setVirtualTourData((prevData) => {
      localStorage.setItem(
        "virtual-tour",
        JSON.stringify({ ...prevData, scenes: [...prevData.scenes, newScene] })
      );
      return {
        ...prevData,
        scenes: [...prevData.scenes, newScene],
      };
    });
  };

  const handleUpdatePositionHotspot = (hotspotIndex, yaw, pitch, type) => {
    setVirtualTourData((prevData) => {
      const newScenes = [...prevData.scenes];
      const currentScene = { ...newScenes[sceneIndex] };

      if (type === "link") {
        const updatedLinkHotspots = currentScene.linkHotspots.map(
          (hotspot, index) =>
            index === hotspotIndex ? { ...hotspot, yaw, pitch } : hotspot
        );
        currentScene.linkHotspots = updatedLinkHotspots;
      } else if (type === "info") {
        const updatedInfoHotspots = currentScene.infoHotspots.map(
          (hotspot, index) =>
            index === hotspotIndex ? { ...hotspot, yaw, pitch } : hotspot
        );
        currentScene.infoHotspots = updatedInfoHotspots;
      }

      newScenes[sceneIndex] = currentScene;

      localStorage.setItem(
        "virtual-tour",
        JSON.stringify({ ...prevData, scenes: newScenes })
      );

      return {
        ...prevData,
        scenes: newScenes,
      };
    });
  };

  const currentScene = virtualTourData?.scenes[sceneIndex];

  return (
    <>
      <div className="flex gap-4 ">
        <div className="w-64 p-4 bg-gray-50 rounded-lg overflow-y-auto h-[calc(100vh-200px)]">
          <div className="flex justify-between mb-5 items-center">
            <div className="flex gap-5 items-center">
              <input
                type="checkbox"
                id="rotate-checkbox"
                className="rounded"
                hidden
                checked={virtualTourData.settings.autorotateEnabled}
                onChange={(e) =>
                  setVirtualTourData((prevState) => ({
                    ...prevState,
                    settings: {
                      ...prevState.settings,
                      autorotateEnabled: e.target.checked,
                    },
                  }))
                }
              />
              <label htmlFor="rotate-checkbox" className="cursor-pointer">
                Rotate
              </label>
            </div>
            {!!virtualTourData?.scenes?.length && (
              <Button
                className="bg-blue-500 text-white px-4 py-2"
                onClick={() => setIsSetting(!isSetting)}
              >
                {isSetting ? "Preview" : "Setting"}
              </Button>
            )}
          </div>
          <UploadImage onUpload={handleImageUpload} />
          <PanoramaList
            virtualTourData={virtualTourData}
            setVirtualTourData={setVirtualTourData}
            setSceneIndex={setSceneIndex}
            sceneIndex={sceneIndex}
          />
          {currentScene && (
            <HotspotList
              sceneIndex={sceneIndex}
              setVirtualTourData={setVirtualTourData}
              virtualTourData={virtualTourData}
            />
          )}
        </div>

        <div className="flex-1">
          {currentScene?.url && (
            <View
              currentScene={currentScene}
              onHotspotUpdate={handleUpdatePositionHotspot}
              settings={virtualTourData.settings}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Setting;
