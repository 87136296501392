import React, { useState } from "react";
import CmsPageHeader from "../components/PageHeader";
import Setting from "./Setting";
import Viewer from "./Viewer";

const initialData = {
  scenes: [],
  settings: {
    mouseViewMode: "drag",
    autorotateEnabled: true,
    fullscreenButton: true,
    viewControlButtons: true,
  },
};

const VirtualTour = () => {
  const data = JSON.parse(localStorage.getItem("virtual-tour")) || initialData;
  const [virtualTourData, setVirtualTourData] = useState(data);
  const [isSetting, setIsSetting] = useState(true);

  return (
    <div className="page-container">
      <CmsPageHeader
        title={
          <div>
            {!isSetting && (
              <span
                className="cursor-pointer text-[40px]"
                onClick={() => setIsSetting(!isSetting)}
              >
                {" < "}
              </span>
            )}
            Virtual Tour
          </div>
        }
      />
      {isSetting && (
        <Setting
          isSetting={isSetting}
          setIsSetting={setIsSetting}
          virtualTourData={virtualTourData}
          setVirtualTourData={setVirtualTourData}
        />
      )}
      {!isSetting && <Viewer data={virtualTourData} />}
    </div>
  );
};

export default VirtualTour;
