import ApiClient from '../apiClient';
import { authFetcher, defaultFetcher } from '../utils/fetcher';
import endPoint from '../endPoint';

const client = new ApiClient(authFetcher);
const defaultClient = new ApiClient(defaultFetcher);

const getMediaList = (data) => client.get(endPoint.MEDIA_GET_LIST, data);
const getMediaDetail = (mediaId, data) =>
  client.get(endPoint.MEDIA_GET_DETAIL.replace(':mediaId', mediaId), data);
const createMedia = (data) =>
  defaultClient.post(endPoint.MEDIA_GET_LIST, data);
const uploadMedia = (data) =>
  defaultClient.uploadFormData(endPoint.MEDIA_UPLOAD, data);
const uploadMediaVideo = (data) =>
  defaultClient.uploadFormData(endPoint.MEDIA_VIDEO_UPLOAD, data);
const updateMedia = (id, data) =>
  defaultClient.put(endPoint.MEDIA_UPDATE.replace(':id', id), data);
const deleteMedia = (id) =>
  defaultClient.delete(endPoint.MEDIA_DELETE.replace(':id', id));

export default {
  getMediaList,
  getMediaDetail,
  uploadMedia,
  uploadMediaVideo,
  updateMedia,
  deleteMedia,
  createMedia,
};
