import React, { useEffect, useRef } from "react";
import Marzipano from "marzipano";
import Info from "../../commons/Hotspot/Info";
import Link from "../../commons/Hotspot/Link";

const View = ({ settings, currentScene, onHotspotUpdate }) => {
  const { url, infoHotspots, linkHotspots, levels, initialViewParameters } =
    currentScene;

  const viewerRef = useRef(null);
  const sceneRef = useRef(null);

  // Initialize viewer
  useEffect(() => {
    const viewerElement = document.getElementById("panorama");
    const viewer = new Marzipano.Viewer(viewerElement, {
      controls: { mouseViewMode: "drag" },
    });

    const geometry = new Marzipano.EquirectGeometry(levels);
    const source = Marzipano.ImageUrlSource.fromString(url);
    const view = new Marzipano.RectilinearView(initialViewParameters);

    const scene = viewer.createScene({ source, geometry, view });

    scene.switchTo({ transitionDuration: 1000 });
    viewerRef.current = viewer;
    sceneRef.current = scene;

    return () => {
      viewer.destroy();
    };
  }, [url, settings]);

  // Autorotation
  useEffect(() => {
    if (settings?.autorotateEnabled && viewerRef.current) {
      const viewerElement = document.getElementById("panorama");

      const currentPitch = viewerRef.current.view().pitch();
      const currentFov = viewerRef.current.view().fov();

      const autorotate = new Marzipano.autorotate({
        yawSpeed: -0.1,
        targetPitch: currentPitch || 0,
        targetFov: currentFov || Math.PI / 2,
      });
      viewerRef.current.startMovement(autorotate); // Start autorotation

      // Handle autorotation when mouse up
      const handleMouseUp = () => {
        if (settings?.autorotateEnabled) {
          viewerRef.current.startMovement(autorotate);
        }
      };

      viewerElement.addEventListener("mouseup", handleMouseUp);

      return () => {
        viewerElement.removeEventListener("mouseup", handleMouseUp);
      };
    }
  }, [viewerRef, settings, url]);

  // Add hotspots
  useEffect(() => {
    if (sceneRef.current) {
      // Destroy old hotspots
      sceneRef.current
        .hotspotContainer()
        .listHotspots()
        .forEach((hotspot) => {
          sceneRef.current.hotspotContainer().destroyHotspot(hotspot);
        });

      // Add infoHotspots
      infoHotspots?.forEach((info, index) => {
        const { pitch, yaw, title, description } = info;

        const hotspotElement = Info({
          index,
          data: {
            description,
            title,
          },
          onHotspotUpdate,
          isView: false,
          sceneRef,
        });

        sceneRef.current
          .hotspotContainer()
          .createHotspot(hotspotElement, { yaw, pitch });
      });

      // Add linkHotspots
      linkHotspots?.forEach((link, index) => {
        const { pitch, yaw, targetSceneId } = link;

        const hotspotElement = Link({
          index,
          data: {
            targetSceneId,
          },
          onHotspotUpdate,
          isView: false,
          sceneRef,
        });

        sceneRef.current
          .hotspotContainer()
          .createHotspot(hotspotElement, { yaw, pitch });
      });
    }
  }, [infoHotspots, linkHotspots, onHotspotUpdate]);

  return (
    <div className="w-full h-screen relative">
      <div id="panorama" className="w-full h-full absolute" />
    </div>
  );
};

export default View;
