/** @format */

import {
  reqSetActiveUnit,
  reqSetIsTransparent,
  reqSetActiveGalleryId,
  reqSetGalleryType,
} from "../../../reduxs/home/action";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import socket from "../../../helper/socket";
import React, { useEffect, useMemo } from "react";
import * as unitExploreAct from "../../../reduxs/unit-explore/action";
import { WEBSOCKET_CHANNEL, ACTION_NAME } from "../../../constants/options";
import { COMPONENTS_ENUM } from "../../../constants/modules";
import _ from "lodash";

const UnitDetailFooter = ({ isPresentation = false, components }) => {
  const dispatch = useDispatch();

  const unit = useSelector((state) => state.unitExplore.selectedUnit);
  const isShowFloorplan = useSelector(
    (state) => state.unitExplore.isShowFloorplan
  );
  const isShowGallery = useSelector((state) => state.unitExplore.isShowGallery);
  const isShowViewLine = useSelector(
    (state) => state.unitExplore.isShowViewLine
  );
  const isShow360 = useSelector((state) => state.unitExplore.isShow360);

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    }
    return () => {
      socket.off(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    };
  }, [isPresentation]);

  const listenerSharedUIAction = ({ content }) => {
    if (content.action === ACTION_NAME.SHOW_UNIT_FLOORPLAN) {
      handleShowFloorplan();
    }

    if (content.action === ACTION_NAME.CLOSE_UNIT_FLOORPLAN) {
      handleCloseFloorplan();
    }

    if (content.action === ACTION_NAME.SHOW_UNIT_GALLERY) {
      handleShowGallery();
    }

    if (content.action === ACTION_NAME.CLOSE_UNIT_GALLERY) {
      handleCloseGallery();
    }

    if (content.action === ACTION_NAME.SHOW_UNIT_VIEWLINE) {
      handleShowViewLine();
    }

    if (content.action === ACTION_NAME.CLOSE_UNIT_VIEWLINE) {
      handleCloseViewLine();
    }

    if (content.action === ACTION_NAME.SHOW_UNIT_360) {
      handleShow360();
    }

    if (content.action === ACTION_NAME.CLOSE_UNIT_360) {
      handleClose360();
    }
  };

  const handleShowFloorplan = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.SHOW_UNIT_FLOORPLAN);
    }
    dispatch(unitExploreAct.reqIsShowViewLine(false));
    dispatch(unitExploreAct.reqIsShowGallery(false));
    let timeout = setTimeout(() => {
      dispatch(unitExploreAct.reqIsShowFloorplan(true));
      dispatch(reqSetGalleryType("floorplanPlaylist"));
      dispatch(reqSetIsTransparent(true));
      clearTimeout(timeout);
    }, 300);
  };

  const handleCloseFloorplan = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLOSE_UNIT_FLOORPLAN);
    }
    dispatch(unitExploreAct.reqIsShowFloorplan(false));
    dispatch(reqSetIsTransparent(false));
  };

  const handleShowGallery = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.SHOW_UNIT_GALLERY);
    }
    dispatch(unitExploreAct.reqIsShowFloorplan(false));
    dispatch(unitExploreAct.reqIsShowViewLine(false));
    let timeout = setTimeout(() => {
      dispatch(reqSetActiveGalleryId(unit?.gallery?.id));
      dispatch(reqSetGalleryType("imagePlaylist"));
      dispatch(reqSetActiveUnit(unit));
      dispatch(unitExploreAct.reqIsShowGallery(!isShowGallery));
      dispatch(reqSetIsTransparent(true));
      clearTimeout(timeout);
    }, 300);
  };

  const handleShowViewLine = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.SHOW_UNIT_VIEWLINE);
    }
    dispatch(unitExploreAct.reqIsShowGallery(false));
    dispatch(unitExploreAct.reqIsShowFloorplan(false));
    let timeout = setTimeout(() => {
      dispatch(reqSetActiveGalleryId(unit?.virtualTour?.id));
      dispatch(reqSetGalleryType("viewlinePlaylist"));
      dispatch(unitExploreAct.reqIsShowViewLine(true));
      dispatch(reqSetIsTransparent(true));
      clearTimeout(timeout);
    }, 300);
  };

  const handleCloseViewLine = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLOSE_UNIT_VIEWLINE);
    }
    dispatch(unitExploreAct.reqIsShowViewLine(false));
  };

  const handleShow360 = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.SHOW_UNIT_360);
    }
    dispatch(unitExploreAct.reqIsShowGallery(false));
    dispatch(unitExploreAct.reqIsShowFloorplan(false));
    dispatch(unitExploreAct.reqIsShowViewLine(false));
    let timeout = setTimeout(() => {
      dispatch(unitExploreAct.reqIsShow360(true));
      dispatch(reqSetIsTransparent(true));
      clearTimeout(timeout);
    }, 300);
  };

  const handleClose360 = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLOSE_UNIT_360);
    }
    dispatch(unitExploreAct.reqIsShow360(false));
    dispatch(reqSetIsTransparent(false));
  };

  const handleCloseGallery = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLOSE_UNIT_GALLERY);
    }
    dispatch(unitExploreAct.reqIsShowGallery(false));
  };

  const renderBtnFloorplan = useMemo(() => {
    if (isShowFloorplan) {
      return (
        <li
          key={_.uniqueId("footer")}
          onClick={() => handleCloseFloorplan()}
          className="text-primary font-sans p-0 px-0 text-xs font-normal leading-none tracking-[1.4px] uppercase cursor-pointer opacity-100 !m-0 underline"
        >
          CLOSE FLOORPLAN
        </li>
      );
    }

    return (
      <li
        key={_.uniqueId("footer")}
        onClick={() => handleShowFloorplan()}
        className="text-primary font-sans p-0 px-0 text-xs font-normal leading-none tracking-[1.4px] uppercase cursor-pointer opacity-100 !m-0"
      >
        VIEW FLOORPLAN
      </li>
    );
  }, [isShowFloorplan]);

  const renderBtnGallery = useMemo(() => {
    if (isShowGallery) {
      return (
        <li
          key={_.uniqueId("gallery")}
          onClick={() => handleCloseGallery()}
          className="text-primary font-sans p-0 px-0 text-xs font-normal leading-none tracking-[1.4px] uppercase cursor-pointer opacity-100 !m-0"
        >
          CLOSE GALLERY
        </li>
      );
    }

    return (
      <li
        key={_.uniqueId("gallery")}
        onClick={() => handleShowGallery()}
        className="text-primary font-sans p-0 px-0 text-xs font-normal leading-none tracking-[1.4px] uppercase cursor-pointer opacity-100 !m-0"
      >
        VIEW GALLERY
      </li>
    );
  }, [isShowGallery]);

  const renderBtnViewLines = useMemo(() => {
    if (isShowViewLine) {
      return (
        <li
          key={_.uniqueId("view-line")}
          onClick={() => handleCloseViewLine()}
          className="text-primary font-sans p-0 px-0 text-xs font-normal leading-none tracking-[1.4px] uppercase cursor-pointer opacity-100 !m-0"
        >
          CLOSE VIEWLINES
        </li>
      );
    }

    return (
      <li
        key={_.uniqueId("view-line")}
        onClick={() => handleShowViewLine()}
        className="text-primary font-sans p-0 px-0 text-xs font-normal leading-none tracking-[1.4px] uppercase cursor-pointer opacity-100 !m-0"
      >
        VIEW VIEWLINES
      </li>
    );
  }, [isShowViewLine]);

  const renderBtn360 = useMemo(() => {
    if (isShow360) {
      return (
        <li
          key={_.uniqueId("360")}
          onClick={() => handleClose360()}
          className="text-primary font-sans p-0 px-0 text-xs font-normal leading-none tracking-[1.4px] uppercase cursor-pointer opacity-100 !m-0"
        >
          CLOSE 360
        </li>
      );
    }

    return (
      <li
        key={_.uniqueId("360")}
        onClick={() => handleShow360()}
        className="text-primary font-sans p-0 px-0 text-xs font-normal leading-none tracking-[1.4px] uppercase cursor-pointer opacity-100 !m-0"
      >
        VIEW 360
      </li>
    );
  }, [isShow360]);

  const renderDetailBtn = useMemo(() => {
    return components
      ?.sort((a, b) => a?.props?.order - b?.props?.order)
      ?.map((v) => {
        const _props = v.props;

        switch (v.name) {
          case COMPONENTS_ENUM.UNIT_FLOORPLAN:
            return _props.visible && renderBtnFloorplan;
          case COMPONENTS_ENUM.UNIT_IMAGE_GALLERY:
            return _props.visible && renderBtnGallery;
          case COMPONENTS_ENUM.UNIT_VIEWLINE_GALLERY:
            return _props.visible && renderBtnViewLines;
          case COMPONENTS_ENUM.UNIT_360_GALLERY:
            return _props.visible && renderBtn360;
          default:
            return null;
        }
      });
  }, [components, isShow360, isShowGallery, isShowViewLine, isShowFloorplan]);

  return (
    <div className="wrap-btn-detail w-full font-sans text-left text-white mt-auto">
      <ul className="flex flex-col gap-[15px] mb-0">{renderDetailBtn}</ul>
    </div>
  );
};

export default UnitDetailFooter;
