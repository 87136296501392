import * as Types from './type';

import hotspotApi from '../../apis/api/hotspot';
import _3dSettings from '../../apis/api/_3dSettings';
import assetApi from '../../apis/api/asset';
import getPageSettings from '../../apis/api/pageSettings';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import * as THREE from 'three';
import config from '../../config/index';
import settingModuleApi from "../../apis/api/page-configuration";

export const actIntroduction = (data) => ({
  type: Types.IS_INTRODUCTION,
  data,
});

export const setIsLoading = (isLoading) => ({
  type: Types.SET_IS_LOADING,
  payload: isLoading,
});

// export const setIsDragging3D = (isDragging3D) => {
//   console.log('are you here', isDragging3D);
//   return {
//     type: Types.IS_DRAGGING3D,
//     payload: isDragging3D,
//   };
// };

// Receive Assets List
const receiveAssetsList = ({ data }) => ({
  type: Types.RECEIVE_ASSETS_LIST,
  payload: data,
});

export const fetchAssetsList = () => async (dispatch, getState) => {
  const assets = await assetApi.getAssetsList();
  dispatch(receiveAssetsList(assets));
};

// Receive Hotspots List
const receiveHotspotsList = ({ data }) => ({
  type: Types.RECEIVE_HOTSPOTS_LIST,
  payload: data,
});

export const fetchHotspotsList = () => async (dispatch, getState) => {
  const hotspots = await hotspotApi.getHotspotsList();
  dispatch(receiveHotspotsList(hotspots));
  dispatch(fetchHsTexts(hotspots.data));
};

// Pages Settings
const receivePageSettings = ({ data }) => ({
  type: Types.RECEIVE_PAGES_SETTINGS,
  payload: data,
});

export const fetchPagesSettings = () => async (dispatch, getState) => {
  const settings = await getPageSettings.getPageSettings();
  dispatch(receivePageSettings(settings));
};

// Receive 3D Settings
const receive3DSettings = ({ data }) => ({
  type: Types.RECEIVE_3D_SETTINGS,
  payload: data[0],
});

export const fetch3DSettings = () => async (dispatch, getState) => {
  const settings = await _3dSettings.get3DSettings();
  dispatch(receive3DSettings(settings));
};

// Receive FBX Files
const receiveFbxFiles = (files) => ({
  type: Types.RECEIVE_FBX_FILES,
  payload: files,
});

const receiveHsTexts = (textures) => ({
  type: Types.RECEIVE_HOTSPOT_TEXTURES,
  payload: textures,
});

export const fetchHsTexts = (hotspots) => async (dispatch, getState) => {
  let textures = [];
  var manager = new THREE.LoadingManager(function () {
    dispatch(receiveHsTexts(textures));
  });

  const loader = new THREE.TextureLoader(manager);
  let requestUrls = new Set();
  hotspots.map((hotspot) => {
    requestUrls.add(`${config.SITE_URL}${hotspot.image_path}`);
  });

  requestUrls.forEach((url) => {
    loader.load(url, (res) => {
      res.url = url.split('/').pop();
      textures.push(res);
    });
  });
};

// Receive GLTF Models
export const receiveGLTFModels = (models) => ({
  type: Types.RECEIVE_GLTF_MODELS,
  payload: models,
});
export const receiveGLTFHelpers = (camHelpers) => ({
  type: Types.RECEIVE_GLTF_CAMERA_HELPERS,
  payload: camHelpers,
});
export const receiveEnvMap = (map) => ({
  type: Types.RECEIVE_ENV_MAP,
  payload: map,
});


export const actSetMeshList = (data) => ({
  type: Types.MESH_LIST,
  data,
});

export const reqGetMeshes = () => (dispatch) => {
  return settingModuleApi
    .getMeshesList()
    .then((data) => {
      dispatch(actSetMeshList(data));
    })
    .catch(() => {});
};