/** @format */

import React, {
    useState,
    useRef,
    useEffect,
    useMemo,
  } from "react";
import { Modal, Button, InputField } from "../../components/commons";
import closeIcon from "../../../../assets/images/close-white.svg";
import galleryApi from "../../../../apis/api/gallery";
import playlistApi from "../../../../apis/api/playlist";
import unitApi from "../../../../apis/api/unit";
import { useDispatch, useSelector } from "react-redux";
import SelectField from "../../configuration/components/selectField";
import { useForm } from "react-hook-form";
import { reqGetListUnits } from "../../../../reduxs/cms/action";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { compact } from "lodash";

const playlistOptions = playlist => playlist.map(it => ({ id: it.id, value: it.name, name: it.name, type: it.type }));

export const availabilityOptions = [
  { value: 'available', name: 'Availability'},
  { value: 'releasing_soon', name: 'Releasing soon'},
  { value: 'sold', name: 'Sold'},
  { value: 'reserved', name: 'Reserved'},
  { value: 'multiple_offers', name: 'Multiple offers'},
  { value: 'leased', name: 'Leased' },
]

const validationSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  level: yup.string().required("Level is required"),
  availabilityStatus: yup
    .object()
    .required("Availability status is required"),
});
  
const UnitAddModal = ({ setIsShow }) => {
    const [playlists, setPlaylists] = useState([]);
    const dispatch = useDispatch();

    const getListPlaylists = async () => {
        const playlists = await playlistApi.getPlaylistList();
        setPlaylists(playlists.data);
    };

    const getListProperties = async () => {
        dispatch(
          reqGetListUnits({
            sortBy: JSON.stringify({
              name: 1,
            }),
          })
        );
      };

    const imagePlaylists = useMemo(() => playlistOptions(playlists.filter(it => it.type === 'images')), [playlists]);
    const floorplanPlaylists = useMemo(() => playlistOptions(playlists.filter(it => it.type === 'floorplans')), [playlists]);
    const viewlinePlaylists = useMemo(() => playlistOptions(playlists.filter(it => it.type === 'viewlines')), [playlists]);
    const playlist360 = useMemo(() => playlistOptions(playlists.filter(it => it.type === '360image')), [playlists]);

    useEffect(() => {
        getListPlaylists()
    }, []);

    const [galleries, setGalleries] = useState([]);
  
    const reorder = (list, startIndex, endIndex) => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);
  
      return result;
    };
  
    const onReorderGallery = async (result) => {
      if (!result.destination) {
        return;
      }
  
      const items = reorder(
        galleries,
        result.source.index,
        result.destination.index
      );
  
      const orderedGalleries = items.map((gallery, index) => ({
        ...gallery,
        order: index,
      }));
  
      setGalleries(orderedGalleries);
      await galleryApi.reorderGallery(orderedGalleries);
    };
  
    const { control, handleSubmit, formState, getValues } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const handleAdd = async (data) => {
        try {
            const dirtyFields = Object.keys(formState.dirtyFields);
            const body = dirtyFields.reduce((acc, cur) => {
                if (['name', 'level'].includes(cur)) {
                  acc[cur] = data[cur]; 
                } else if (['availabilityStatus'].includes(cur)) {
                  acc[cur] = data[cur].value;
                } else {
                  acc[cur] = data[cur].id;
                  acc[`${cur}Override`] = playlists.find(p => p.id === data[cur].id).medias?.map(it => it.id);
                }
                return acc;
            }, {})
            const res = await unitApi.createUnit({...body, autogen: false, "3d_mesh": data.name });
            if (res.data?.name) {
                toast.success("Create unit successfully");
                return await getListProperties()
            }
            throw new Error(res.message)
        } catch (error) {
            toast.error(error.message ?? "Something's wrong");
        } finally {
            setIsShow(false)
        }
    };
  
    return (
        <div className="bg-white rounded-lg relative">
            <div className="mb-5 flex justify-between">
                <InputField
                wrapperClassName="w-[49%]"
                required
                label="Unit number"
                controller={{ control, name: "name" }}
                inputProps={{ placeholder: "Please input unit number" }}
                />
                <InputField
                wrapperClassName="w-[49%]"
                required
                label="Level"
                controller={{ control, name: "level" }}
                inputProps={{ placeholder: "Please input unit level" }}
                />
            </div>
            <SelectField
                wrapperOptionsClassName="p-[10px] rounded-[8px] border-[#000] mb-4"
                labelClassName="text-[14px] text-[#5d5d5d] mb-[10px] font-[500]"
                controller={{ control, name: "availabilityStatus" }}
                required
                label="Availability"
                options={availabilityOptions}
                placeholder="Please select availability status"
            />
            <SelectField
                wrapperOptionsClassName="p-[10px] rounded-[8px] border-[#000] mb-4"
                labelClassName="text-[14px] text-[#5d5d5d] mb-[10px] font-[500]"
                controller={{ control, name: "imagePlaylist" }}
                required
                label="Gallery playlists"
                options={imagePlaylists}
                placeholder="Please select playlist"
            />
            <SelectField
                wrapperOptionsClassName="p-[10px] rounded-[8px] border-[#000] mb-4"
                labelClassName="text-[14px] text-[#5d5d5d] mb-[10px] font-[500]"
                controller={{ control, name: "floorplanPlaylist" }}
                required
                label="Floorplan playlists"
                options={floorplanPlaylists}
                placeholder="Please select playlist"
            />
            <SelectField
                wrapperOptionsClassName="p-[10px] rounded-[8px] border-[#000] mb-4"
                labelClassName="text-[14px] text-[#5d5d5d] mb-[10px] font-[500]"
                controller={{ control, name: "viewlinePlaylist" }}
                required
                label="Viewline playlists"
                options={viewlinePlaylists}
                placeholder="Please select playlist"
            />
            <SelectField
                wrapperOptionsClassName="p-[10px] rounded-[8px] border-[#000] mb-4"
                labelClassName="text-[14px] text-[#5d5d5d] mb-[10px] font-[500]"
                controller={{ control, name: "360ImagePlaylist" }}
                required
                label="360 playlists"
                options={playlist360}
                placeholder="Please select playlist"
            />
            <div className="flex gap-3 mt-8">
                <Button
                    disabled={formState?.isSubmitting}
                    variant="text"
                    onClick={() => setIsShow(false)}
                    className="flex-1"
                    >
                    Cancel
                </Button>
                <Button
                    disabled={formState?.isSubmitting || !formState?.isDirty}
                    isLoading={formState?.isSubmitting}
                    onClick={handleSubmit(handleAdd)}
                    className="bg-yellow-200 text-black hover:bg-yellow-300 hover:text-black disabled:hover:bg-yellow-200 disabled:hover:text-black flex-1"
                    >
                    Save
                </Button>
            </div>
        </div>
    );
  };
  
  export default UnitAddModal;
  